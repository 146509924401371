<template>
  <a
    class="bot_open co-btn-counseling co-display-12 fixed inset-x-0 bottom-3 z-[1020] mx-auto w-60 font-bold duration-500 lg:w-[16.125rem]"
    href="/counseling/htmlcss-schedule"
    :class="isVisible ? 'fade-in' : 'fade-out'"
  >
    まずは相談してみる（無料）
  </a>
</template>

<script lang="ts">
interface Data {
  isVisible: boolean
  targetSections: string[]
}

const options = {
  data(): Data {
    return {
      isVisible: false,
      targetSections: ['#cover', '#apply', '#counseling', '#footer'],
    }
  },
  async mounted() {
    window.addEventListener('scroll', this.handleScroll)
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll)
  },
  methods: {
    handleScroll(): void {
      const scrollPosition = window.scrollY + 100
      this.isVisible = scrollPosition > 100 && !this.isSectionVisible()
    },
    isSectionVisible(): boolean {
      return this.targetSections.some((id) => {
        const element = document.querySelector(id)
        if (element) {
          const rect = element.getBoundingClientRect()
          return rect.top <= window.innerHeight && rect.bottom >= 0
        }
        return false
      })
    },
  },
}

export default defineNuxtComponent(options)
</script>

<style scoped>
.fade-out {
  transform: translate(0, calc(100% + 0.75rem));
}

.fade-in {
  transform: translate(0, 0%);
}
</style>
